import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Grid, Box, Typography, Card } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import { styleConfig } from '../../config/config';

import {
  agreementTermsMarks,
  annualMileageMarks,
  bodyTypes,
  fuelTypes,
  initialRentalMarks,
  pricingMarks,
  tooltipValues,
  transmissionTypes,
  leaseTypeButtons,
} from '../constants';

import {
  handleFilterVariants,
  handleGetInitialPreferences,
  handleInitialVariants,
  handleUpdateBodyStyle,
  handleUpdateBrands,
  handleUpdatePage,
  handleUpdatePrice,
  handleUpdateTransmission,
  handleUpdateTerms,
  handleUpdateFuel,
  handleUpdateAnnualMileage,
  handleUpdateInitialRental,
  handleReset,
  handleUpdateLeaseType,
} from '../actions/specialOffers';

import { IAppState, LeaseType } from '../defs/declarations';
import { WordpressWpCarEdge } from '../defs/generated-models';

import InputRange from './InputRange';
import InputSelect from './InputSelect';
import InputSelectMulti from './InputSelectMulti';
import MuiButton from './MuiButton';
import GroupedButton from './GroupedButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: styleConfig.color.bg.white,
  },
  cardRoot: {
    padding: '25px 50px 25px 50px',
    position: 'relative',
    marginTop: -80,
    marginBottom: 35,
    borderRadius: 0,
    border: '1px solid #EBEBEB',
    boxShadow: '0 5px 20px 0 rgba(0,0,0,0.1)',
    zIndex: 750,

    [theme.breakpoints.down('md')]: {
      padding: 35,
      top: 0,
      marginTop: 40,
    },
  },
  title: {
    marginBottom: 20,
    fontSize: 20,
    fontWeight: 600,
    fontFamily: styleConfig.fonts.body,
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      '&:first-of-type': {
        marginRight: 25,
      },
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 15,

      '& > button': {
        '&:first-of-type': {
          marginRight: '0!important',
        },

        width: '100%',
        marginBottom: 15,
      },
    },
  },
  buttonIcon: {
    marginRight: 5,
  },
}));

const LeasePreferencesWidget: React.FunctionComponent<LeasePreferencesWidgetProps> = (props) => {
  const classes = useStyles();

  const {
    handleUpdateBrands,
    handleUpdateBodyStyle,
    handleUpdatePrice,
    handleUpdateTransmission,
    handleUpdateFuel,
    handleUpdateTerms,
    handleUpdateInitialRental,
    handleUpdateAnnualMileage,
    handleUpdateLeaseType,
    handleReset,
    handleFilterVariants,
    agreementTerms,
    annualMileage,
    bodyStyles,
    leaseType,
    initialVariants,
    brands,
    fuelType,
    initialRental,
    isLoading,
    isFiltered,
    isSearch,
    price,
    transmission,
    variants,
    postsPerPage,
    sortValue,
  } = props;

  const handleFilter = () => {
    handleFilterVariants(
      true,
      leaseType,
      agreementTerms,
      initialRental,
      annualMileage,
      initialVariants,
      variants,
      fuelType,
      brands,
      bodyStyles,
      transmission,
      price,
      sortValue,
      postsPerPage
    );
  };

  // Filters
  const handleBrands = (val) => {
    handleUpdateBrands(val);
  };

  const handleBodyStyle = (val) => {
    handleUpdateBodyStyle(val);
  };

  const handlePrice = (val) => {
    if (val !== price) {
      handleUpdatePrice(val);
    }
  };

  const handleTransmission = (val) => {
    if (val !== transmission) {
      handleUpdateTransmission(val);
    }
  };

  const handleFuel = (val) => {
    if (val !== fuelType) {
      handleUpdateFuel(val);
    }
  };

  const handleTerms = (val) => {
    if (val !== agreementTerms) {
      handleUpdateTerms(leaseType, val, initialRental, annualMileage);
    }
  };

  const handleRental = (val) => {
    if (val !== initialRental) {
      handleUpdateInitialRental(leaseType, agreementTerms, val, annualMileage);
    }
  };

  const handleLeaseType = (val) => {
    if (val !== leaseType) {
      handleUpdateLeaseType(val);
    }
  };

  const handleMileage = (val) => {
    if (val !== annualMileage) {
      handleUpdateAnnualMileage(leaseType, agreementTerms, initialRental, val);
    }
  };

  const query = useStaticQuery(graphql`
    {
      allWordpressWpBrand(
        filter: {
          brand_meta_fields: { hasParent: { eq: false } }
          acf: { brand_page: { eq: true } }
          slug: { nin: "demo" }
        }
      ) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `);

  // All top-level brands based on the above query
  const brandTypes = query.allWordpressWpBrand.edges.map((brand) => {
    return {
      label: brand.node.name,
      value: brand.node.slug,
    };
  });

  return (
    <Box className={classes.root} id="filter-widget">
      <Container maxWidth="xl">
        <Card className={classes.cardRoot}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {isSearch && (
                <GroupedButton
                  handleClick={handleLeaseType}
                  items={leaseTypeButtons}
                  selected={leaseType}
                />
              )}
              {!isSearch && (
                <Typography component="div" className={classes.title} variant="h4">
                  Set your lease preferences
                </Typography>
              )}
            </Grid>
          </Grid>

          {isSearch && (
            <React.Fragment>
              <Grid container spacing={6}>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <InputSelectMulti
                    data={bodyTypes}
                    handleChange={handleBodyStyle}
                    label
                    labelText="Body Style"
                    placeholder="e.g. Estate"
                    value={bodyStyles}
                    isTooltip
                    tooltipContent={tooltipValues.bodyStyle}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  {brands && (
                    <InputSelectMulti
                      data={brandTypes}
                      handleChange={handleBrands}
                      label
                      labelText="Brands"
                      placeholder="e.g. BMW"
                      value={brands}
                      isTooltip
                      tooltipContent={tooltipValues.brands}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  {fuelType && (
                    <InputSelect
                      fullWidth
                      handleChange={handleFuel}
                      isTooltip
                      label
                      labelText="Fuel type"
                      menuItems={fuelTypes}
                      name="fuelType"
                      tooltipContent={tooltipValues.fuelType}
                      value={fuelType}
                    />
                  )}
                </Grid>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  {transmission && (
                    <InputSelect
                      fullWidth
                      handleChange={handleTransmission}
                      isTooltip
                      label
                      labelText="Transmission"
                      menuItems={transmissionTypes}
                      name="transmission"
                      tooltipContent={tooltipValues.transmission}
                      value={transmission}
                    />
                  )}
                </Grid>

                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  {price && handlePrice && (
                    <InputRange
                      fullWidth
                      handleChange={handlePrice}
                      isTooltip
                      isMulti
                      labelText="Monthly price"
                      marks={pricingMarks}
                      max={1500}
                      min={150}
                      name="pricing"
                      step={150}
                      tooltipContent={tooltipValues.pricing}
                      value={price}
                    />
                  )}
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          {/* Pricing */}
          <Grid container spacing={4}>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <InputRange
                fullWidth
                handleChange={handleRental}
                isTooltip
                labelText="Initial Rental (Months)"
                marks={initialRentalMarks}
                max={12}
                min={3}
                name="initialRental"
                step={3}
                tooltipContent={tooltipValues.initialRental}
                value={initialRental}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <InputRange
                fullWidth
                handleChange={handleTerms}
                isTooltip
                labelText="Contract Hire Term (Months)"
                marks={agreementTermsMarks}
                max={48}
                min={24}
                name="agreementTerms"
                step={12}
                tooltipContent={tooltipValues.agreementTerms}
                value={agreementTerms}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <InputRange
                fullWidth
                handleChange={handleMileage}
                isTooltip
                labelText="Annual Mileage"
                marks={annualMileageMarks}
                max={20000}
                min={8000}
                name="annualMileage"
                step={1000}
                tooltipContent={tooltipValues.annualMileage}
                value={annualMileage}
              />
            </Grid>
          </Grid>

          {isSearch && (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.buttonGroup}>
                  {handleReset && (
                    <MuiButton
                      disabled={!isFiltered || isLoading}
                      variant="outlined"
                      onClick={() => handleReset()}
                    >
                      <ClearAllIcon className={classes.buttonIcon} />
                      Reset
                    </MuiButton>
                  )}

                  <MuiButton
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    onClick={() => handleFilter()}
                  >
                    <SearchIcon className={classes.buttonIcon} /> Search
                  </MuiButton>
                </Box>
              </Grid>
            </Grid>
          )}
        </Card>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    agreementTerms: state.specialOffers.agreementTerms,
    annualMileage: state.specialOffers.annualMileage,
    bodyStyles: state.specialOffers.bodyStyles,
    brands: state.specialOffers.brands,
    fuelType: state.specialOffers.fuelType,
    initialRental: state.specialOffers.initialRental,
    leaseType: state.specialOffers.leaseType,
    initialVariants: state.specialOffers.initialVariants,
    isFiltered: state.specialOffers.isFiltered,
    isLoading: state.specialOffers.isLoading,
    isLoadingPosts: state.specialOffers.isLoadingPosts,
    postsPerPage: state.specialOffers.postsPerPage,
    postsToShow: state.specialOffers.postsToShow,
    price: state.specialOffers.price,
    sortValue: state.specialOffers.sortValue,
    transmission: state.specialOffers.transmission,
    variants: state.specialOffers.variants,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleFilterVariants,
      handleGetInitialPreferences,
      handleInitialVariants,
      handleReset,
      handleUpdateLeaseType,
      handleUpdateAnnualMileage,
      handleUpdateBodyStyle,
      handleUpdateBrands,
      handleUpdateFuel,
      handleUpdateInitialRental,
      handleUpdatePage,
      handleUpdatePrice,
      handleUpdateTerms,
      handleUpdateTransmission,
    },
    dispatch
  );

interface LeasePreferencesWidgetProps {
  agreementTerms: number;
  annualMileage: number;
  bodyStyles?: [string];
  brands?: [string];
  fuelType: string;
  handleUpdateLeaseType: Function;
  handleReset: Function;
  handleUpdateAnnualMileage: Function;
  handleUpdateBodyStyle: Function;
  handleUpdateBrands: Function;
  handleUpdateFuel: Function;
  handleUpdateInitialRental: Function;
  handleUpdatePage: Function;
  handleUpdatePrice: Function;
  handleUpdateTerms: Function;
  handleUpdateTransmission: Function;
  handleFilterVariants: Function;
  initialRental: number;
  initialVariants: Array<WordpressWpCarEdge>;
  isFiltered?: boolean;
  isLoading: boolean;
  isLoadingPosts: boolean;
  isSearch?: boolean;
  leaseType: LeaseType;
  postsPerPage: number;
  postsToShow: number;
  price?: Array<number>;
  sortValue: string;
  transmission?: string;
  variants: Array<WordpressWpCarEdge>;
}

export default connect(mapStateToProps, mapDispatchToProps)(LeasePreferencesWidget);
