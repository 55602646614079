import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { graphql } from 'gatsby';

import { styleConfig } from '../../config/config';
import { decodeObjectSpecialCharacters } from '../utils/utils';

import Hero from '../components/Hero';
import LayoutPage from '../components/LayoutPage';
import LeasePreferencesWidget from '../components/LeasePreferencesWidget';
import Loading from '../components/LeaseBuilder/CustomiseLease/Steps/Loading';
import PostsList from '../components/PostsList';
import Section from '../components/Common/Section';
import SEO from '../components/SEO';
import SpecialOffersItem from '../components/SpecialOffersItem';

// Actions
import {
  handleUpdateAnnualMileage,
  handleUpdateTerms,
  handleUpdateInitialRental,
  handleGetInitialPreferences,
} from '../actions/specialOffers';
import BackToTop from '../components/BackToTop';
import ContentModules from '../components/ContentModules';

const SpecialOffersPage: React.FunctionComponent<SpecialOffersPageProps> = (props) => {
  const { data } = props;
  const yoastMeta = decodeObjectSpecialCharacters(data.wordpressPage.yoast_meta);

  const {
    initialRental,
    isLoading,
    annualMileage,
    agreementTerms,
    handleGetInitialPreferences,
    leaseType,
  } = props;

  const useStyles = makeStyles(() => ({
    footer: {
      paddingTop: 25,
      paddingBottom: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    footerTitle: {
      color: styleConfig.color.text.black,
    },
    noOffers: {
      fontSize: 22,
      fontWeight: 600,
    },
  }));

  useEffect(() => {
    handleGetInitialPreferences();
  }, [handleGetInitialPreferences]);

  const classes = useStyles();
  let count = 0;

  return (
    <main>
      <SEO
        description={yoastMeta.yoast_wpseo_metadesc}
        ogDesc={yoastMeta.yoast_wpseo_facebook_description}
        ogImg={yoastMeta.yoast_wpseo_facebook_image}
        ogTitle={yoastMeta.yoast_wpseo_facebook_title}
        title={yoastMeta.yoast_wpseo_title}
        twitterDesc={yoastMeta.yoast_wpseo_twitter_description}
        twitterImg={yoastMeta.yoast_wpseo_twitter_image}
        twitterTitle={yoastMeta.yoast_wpseo_twitter_title}
      />

      <LayoutPage bg="#FFFFFF">
        <Hero
          buttons={data.wordpressPage.acf.buttons}
          content={data.wordpressPage.acf.content}
          headline={
            (data.wordpressPage.page_meta_fields && data.wordpressPage.page_meta_fields.title) ||
            data.wordpressPage.title.rendered
          }
          slides={data.wordpressPage.acf.slides}
          subtitle={data.wordpressPage.acf.pre_title}
          isMonth
          isSmall
        />

        <React.Fragment>
          <LeasePreferencesWidget />

          {isLoading ? (
            <Loading message="Loading.." height={750} />
          ) : (
            <React.Fragment>
              <PostsList>
                {!isEmpty(data.allWordpressWpCar.edges) ? (
                  <React.Fragment>
                    {data.allWordpressWpCar.edges.map((post, index) => {
                      count++;

                      if (count === 4) {
                        count = 1;
                      }

                      return (
                        <Grid
                          item
                          key={`special-offers=${index}`}
                          lg={4}
                          md={6}
                          sm={12}
                          xl={4}
                          xs={12}
                        >
                          <SpecialOffersItem
                            agreementTerms={agreementTerms}
                            annualMileage={annualMileage}
                            initialRental={initialRental}
                            variant={post.node}
                            leaseType={leaseType}
                            isSearch={false}
                            index={count}
                          />
                        </Grid>
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="body2" className={classes.noOffers}>
                      No special offers currently available.
                    </Typography>
                  </Grid>
                )}
              </PostsList>

              {!isEmpty(data.allWordpressWpCar.edges) && (
                <footer className={classes.footer}>
                  <Typography className={classes.footerTitle}>
                    Initial payment & terms apply, subject to status
                  </Typography>
                </footer>
              )}

              <Section nopad>
                {data.wordpressPage.acf &&
                  data.wordpressPage.acf.content_modules_page &&
                  data.wordpressPage.acf.content_modules_page.map((module, index) => (
                    <ContentModules key={`cmodules-${index}`} index={index} module={module} />
                  ))}
              </Section>
            </React.Fragment>
          )}
        </React.Fragment>
      </LayoutPage>

      <BackToTop isVisible anchorId="___gatsby" />
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    agreementTerms: state.specialOffers.agreementTerms,
    annualMileage: state.specialOffers.annualMileage,
    initialRental: state.specialOffers.initialRental,
    isLoading: state.specialOffers.isLoading,
    leaseType: state.specialOffers.leaseType,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleUpdateTerms,
      handleGetInitialPreferences,
      handleUpdateAnnualMileage,
      handleUpdateInitialRental,
    },
    dispatch
  );

interface SpecialOffersPageProps {
  data: any; // TODO:
  agreementTerms: number;
  annualMileage: number;
  handleGetInitialPreferences: Function;
  initialRental: number;
  isLoading: boolean;
  pageContext: any;
  leaseType: string;
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOffersPage);

export const query = graphql`
  query specialOffersPageQuery($id: Int) {
    allWordpressWpCar(
      filter: {
        acf: { special_offer: { eq: "1" } }
        post_meta_fields: { variant_url: { ne: "" } }
        status: { eq: "publish" }
        slug: { ne: "dummy" }
      }
    ) {
      edges {
        node {
          id
          title
          wordpress_id
          slug
          title
          acf {
            title
            disable_addons
            special_offer
            special_offer_info_bar
          }
          post_meta_fields {
            capid
            title
            brand_title
            variant_url
            trim
            fuel
            brands {
              name
            }
            specs {
              spec_BHP
              spec_0_62
              spec_MPG3
              spec_CO2
            }
            price_24m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_36m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
            price_48m {
              mileage_8k
              mileage_9k
              mileage_10k
              mileage_11k
              mileage_12k
              mileage_13k
              mileage_14k
              mileage_15k
              mileage_16k
              mileage_17k
              mileage_18k
              mileage_19k
              mileage_20k
            }
          }
        }
      }
    }
    wordpressPage(wordpress_id: { eq: $id }) {
      wordpress_id
      slug
      status
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
      acf {
        buttons {
          button {
            title
            url
            target
          }
        }
        content
        slides {
          highlight {
            post_meta_fields {
              capid
              brand_title
              title
              variant_url
              __typename
            }
          }
          image {
            alt_text
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  height: 856
                  width: 1600
                  transformOptions: {
                    cropFocus: CENTER
                    duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                  }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        content_modules_page {
          ... on WordPressAcf_multi_image_blocks {
            __typename
            title
            pre_title
            mib_rows {
              mib_columns {
                mib_title
                mib_link {
                  title
                  url
                  target
                }
                mib_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        width: 497
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_intro_features {
            __typename
            title
            description
            intro
          }
          ... on WordPressAcf_intro_steps {
            __typename
            id
            content {
              blockContent: content
              steps {
                content
                title
              }
            }
            overview {
              buttons {
                button {
                  title
                  target
                  url
                }
                type
              }
              pre_title
              title
            }
          }
          ... on WordPressAcf_magazine_blocks {
            __typename
            rows {
              cols {
                link {
                  url
                  title
                  target
                }
                contents
                subtext_colour
                subtitle
                text_align
                title
                title_colour
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        width: 800
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_special_offers {
            __typename
            id
            title
            pre_title
            tabs {
              title
              derivatives {
                guid
                post_meta_fields {
                  capid
                  price_24m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  price_36m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  price_48m {
                    mileage_8k
                    mileage_9k
                    mileage_10k
                    mileage_11k
                    mileage_12k
                    mileage_13k
                    mileage_14k
                    mileage_15k
                    mileage_16k
                    mileage_17k
                    mileage_18k
                    mileage_19k
                    mileage_20k
                  }
                  specs {
                    spec_BHP
                    spec_0_62
                    spec_MPG3
                    spec_CO2
                  }
                  special_offer
                  special_offer_info_bar
                  brand_title
                  title
                  variant_url
                }
              }
            }
          }
          ... on WordPressAcf_image_with_content {
            __typename
            id
            colour_mode
            image_align
            title
            blockContent: content
            image {
              alt_text
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    height: 673
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          ... on WordPressAcf_accordion_faq {
            __typename
            id
            colour_mode
            title
            pre_title
            add_contact_button
            items {
              content_answer
              title_question
            }
          }
          ... on WordPressAcf_brand_blocks {
            __typename
            pre_title
            title
            rows {
              cols {
                link {
                  url
                  title
                  target
                }
                contents
                subtitle
                title
                description
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 80
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
            id
          }
          ... on WordPressAcf_reviews {
            __typename
            id
            reviews {
              post_title
              post_content
              acf {
                source
                rating
              }
            }
            pre_title
            title
            is_dark
          }
          ... on WordPressAcf_call_to_action {
            __typename
            buttons {
              type
              button {
                title
                target
                url
              }
            }
            pre_title
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: {
                      duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                    }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WordPressAcf_image_with_content_multiple {
            __typename
            id
            title
            submodules {
              colour_mode
              content
              image_align
              image {
                alt_text
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      height: 673
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          ... on WordPressAcf_intro {
            __typename
            id
            pre_title
            title
            intro
          }
          ... on WordPressAcf_general_content {
            __typename
            id
            main_content
            supporting_content {
              content
            }
          }
          ... on WordPressAcf_accordion_faq_review {
            __typename
            id
            items {
              content_answer
              title_question
            }
            title
            pre_title
            add_contact_button
            featured_review {
              post_content
              post_title
              acf {
                rating
              }
            }
          }
          ... on WordPressAcf_slider {
            __typename
            id
            title
            pre_title
            slides {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    transformOptions: {
                      duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                    }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WordPressAcf_video {
            __typename
            id
            youtube_video_id
          }
          ... on WordPressAcf_large_links {
            __typename
            id
            blocks {
              pre_title
              title
              type
              overlay
              link {
                title
                url
                target
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 80
                      height: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      transformOptions: {
                        duotone: { highlight: "#000000", shadow: "#000000", opacity: 50 }
                      }
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
      page_meta_fields {
        title
      }
      title
    }
  }
`;
